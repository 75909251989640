<template>
  <d-side-bar
    :model="display"
    :backdrop="false"
    identifier="userSideModal"
    width="700px"
    header-class="header-modal"
    @shown="$emit('shown')"
    @change="$emit('change')"
    @hidden="$emit('hidden')"
  >
    <div
      slot="header"
    >
      <label class="p-3 modal-title">
        {{ $t('views.top-bar.users.create-or-update.add-user') }}
      </label>
    </div>
    <div
      slot="body"
    >
      <div class="container-fluid">
        <validation-observer
          ref="userClubObserver"
          slim
        >
          <create-or-update-user-form
            :user="user"
            :validation="validation"
          />
        </validation-observer>
      </div>
    </div>
    <div class="footer-bottom" slot="footer">
      <div class="border-bottom-grey-dark mt-3 mb-3"/>

      <d-button
        text="general.actions.save"
        class="pull-right d-btn-sm btn d-btn-primary-new font-text-title mr-3"
        @on:button-click="onSave"
      />
    </div>

  </d-side-bar>
</template>
<script>
import {postUserClub, putUserClub} from "@api/doinsport/services/user-club/user-club.api";
import UserClub from "@/classes/doinsport/UserClub";

export default {
  data: () => ({
    isBusy: false,
    validation: require('@validation/entities/doinsport/UserClub.json')
  }),
  components: {
    CreateOrUpdateUserForm: () => import('@form/user/createOrUpdate/CreateOrUpdateUserForm')
  },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: this,
    },
  },
  methods: {
    onSave() {
      this.validation.phoneNumber.required = true;

      if (!this.isBusy) {
        this.isBusy = true;
        this.$refs.userClubObserver
          .validate()
          .then((valid) => {
            if (valid) {
              const serializedUser = new UserClub(this.user, {serialize: true});
              delete serializedUser.avatar;

              if (serializedUser.id === null) {
                postUserClub(serializedUser)
                  .then((response) => {
                    if (this.user.avatar) {
                      const payload = {entry: 'user', url: response.data['@id'], target: 'postUserClubAvatar'};
                      this.$uploadFile(this.user.avatar, payload)
                        .then((response) => {
                          this.$emit('hidden');
                        })
                      ;
                    } else {
                      this.$emit('hidden');
                    }
                  })
                  .finally(() => {
                    this.isBusy = false;
                  })
                ;
              } else {
                delete serializedUser.plainPassword;
                putUserClub(serializedUser)
                  .then((response) => {
                    this.$emit('hidden');

                  })
                  .finally(() => {
                    this.isBusy = false;
                    this.validation.phoneNumber.required = false;
                  })
                ;
              }
            } else {
              this.isBusy = false;
            }
          })
        ;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-title {
  text-align: left;
  font: normal normal 700 25px Avenir;
  letter-spacing: 0.78px;
  color: #0b2772;
  opacity: 1;
}

.footer-bottom {
  position: absolute;
  bottom: 75px;
  width: -webkit-fill-available;
}
</style>
